import { render, staticRenderFns } from "./TrustServiceCategory.vue?vue&type=template&id=46854ac4&scoped=true&"
import script from "./TrustServiceCategory.vue?vue&type=script&lang=js&"
export * from "./TrustServiceCategory.vue?vue&type=script&lang=js&"
import style0 from "./TrustServiceCategory.vue?vue&type=style&index=0&id=46854ac4&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "46854ac4",
  null
  
)

export default component.exports