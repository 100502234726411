<template>
  <div class="TrustServiceCategor">
    <div class="banner">
      <img width="100%" src="../assets/banner8.png" alt="" />
      <div class="title">
        <div class="h1">TRUST SERVICE CATEGORY</div>
      </div>
    </div>

    <div class="nav">
      <div class="content" @click="handleCategor('SavingsTrust')">
        <img src="../assets/SAVINGSTRUST1.c8ef407d.png" alt="" />
        <div class="title1">Inheritance Savings Trust</div>
        <div class="text">
          Inheritance savings trust is set up when the settlor transfers one’s
          income to the trust for wealth planning. The client earns the growth
          while enjoying all the benefits that the trust has to offer.
        </div>
      </div>
      <div class="content" @click="handleCategor('FamilyTrust')">
        <img src="../assets/TrustServiceCategor2.png" alt="" />
        <div class="title1">Family Trust</div>
        <div class="text">
          Family trust refers to the trust applied in the field of family wealth
          management. The trustee must manage the family wealth according to the
          wishes of the settlor to achieve family wealth management and
          inheritance purposes. The beneficiaries are mainly family members.
        </div>
      </div>
      <div class="content" @click="handleCategor('RealEstateTrust')">
        <img src="../assets/TrustServiceCategor3.png" alt="" />
        <div class="title1">Real Estate Trust</div>
        <div class="text">
          Real estate trust refers to a trust deed between the settlor and the
          trustee, and the transfer of the immovable property in its name to the
          trustee. The trustee manages or distributes its immovable property for
          a specific purpose or the benefit of the beneficiary.
        </div>
      </div>
      <div class="content" @click="handleCategor('Insurance+SavingsTrust')">
        <img src="../assets/TrustServiceCategor4.png" alt="" />
        <div class="title1">Insurance Trust</div>
        <div class="text">
          Insurance Trust is a trust whereby the trustee is appointed to manage
          the proceeds of an insurance policy upon the death of the settlor. The
          trustee becomes the owner of the insurance policy and the settlor
          remains the insured person under the policy. Upon its maturity or when
          the proceeds are payable due to death or permanent disability of the
          settlor, the trustee will collect the proceeds and administer it in
          accordance with the settlor’s wishes as stated in the trust deed for
          the benefit of the beneficiaries. The trustee can also be instructed
          to pay the regular premiums of the policy on behalf of the
          beneficiaries.
        </div>
      </div>
      <div class="content" @click="handleCategor('CharityTrust')">
        <img src="../assets/TrustServiceCategor5.png" alt="" />
        <div class="title1">Charity Trust</div>
        <div class="text">
          Charity trust means that the settlor entrusts its wealth to the
          trustee for the charitable purpose, and the trustee manages and
          distributes it in the name of the trustee according to the settlor's
          wishes to conducts charitable activities.
        </div>
      </div>
      <div class="content" @click="handleCategor('MigrationTrust')">
        <img src="../assets/TrustServiceCategor6.png" alt="" />
        <div class="title1">Migration Trust</div>
        <div class="text">
          Migration trust is dedicated to wealth management for migration
          purposes and is inclusive of migration planning, wealth management and
          planning in order to facilitate the application procedure, shortening
          processing time and maximizing the chances of success.
        </div>
      </div>
      <div class="content" @click="handleCategor('EducationTrust')">
        <img src="../assets/TrustServiceCategor7.png" alt="" />
        <div class="title1">Education Trust</div>
        <div class="text">
          Education trust is aimed at preserving the assets dedicated to funding
          the tertiary education of your children so that they can enjoy peace
          of mind to complete their studies uninterrupted.
        </div>
      </div>
      <div class="content" @click="handleCategor('SpecialKidsTrust')">
        <img src="../assets/TrustServiceCategor8.png" alt="" />
        <div class="title1">Special Kids' Trust</div>
        <div class="text">
          Parents of special children who are not likely to be able to live
          independently can set up this trust, which will ensure both the
          physical and financial wellbeing of that child for life. Such a trust
          can see to the fulfillment of your aspirations as a loving parent
          after your lifetime.
        </div>
      </div>
      <div class="content" @click="handleCategor('Others')">
        <img src="../assets/TrustServiceCategor9.jpg" alt="" />
        <div class="title1">Others</div>
        <div class="text">
          Chattel Trust,Equity Trust,Credit Trust,Retirement Trust,Will
          Trust,Antique Trust,Revocable trust,Irrevocable Trust,Artwork Trust
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import axios from "axios";
export default {
  methods: {
    handleCategor(data) {
      this.$router.push(`/Categor?id=${data}`);
    },
  },
  /* mounted() {
    console.log(1);
    axios
      .get("https://www.hahalook.com/addons/cms/wxapp.archives/detail", {
        params: {
          id: 364,
          _ajax: 1,
        },
      })
      .then(function (response) {
        console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, */
};
</script>
<style lang="scss" scoped>
.banner {
  width: 100vw;
  /*  height: 994px;
  background: url("../assets/banner8.png") no-repeat;
  background-size: cover; */
  position: relative;
}
img {
  transition: all 0.6s;
  cursor: pointer;
}
.title {
  color: #fff;
  display: flex;
  width: 100vw;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* z-index: 100; */
  position: absolute;
  top: 45%;
  font-size: 55px;
}
.nav {
  width: 60vw;
  margin: 32px auto -97px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  img {
    width: 100%;
  }
  .content {
    width: 31%;
    overflow: hidden;
    .title1 {
      font-weight: 500;
      font-family: "Georgia";
    }
    div {
      text-align: left;
      font-size: 20px;
    }
    .OTHERS {
      display: flex;
      border: 1px solid;
      /* height: 61%; */
      justify-content: center;
      align-items: center;
      p {
        margin: 21.5% 0px;
      }
    }
    .text {
      font-size: 16px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      margin-bottom: 35px;
      margin-top: 5px;
      line-height: 1.4;
      font-family: contert;
    }
  }
  .content:hover {
    div {
      color: #a95228;
    }
    img {
      transform: scale(1.1);
    }
  }
}
@media (min-width: 1025px) and (max-width: 1500px) {
  .nav {
    width: 80vw;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .title {
    font-size: 36px;
  }
  .nav {
    width: 80vw;
    margin: 65px auto 100px;
    .content {
      .OTHERS {
        p {
          margin: 20% 0px;
        }
      }
    }
  }
}
@media (max-width: 767px) {
  // // 手机背景图
  // .banner img {
  //   height: 520px;
  //   object-fit: cover;
  // }
  .title {
    font-size: 30px;
    top: 35%;
  }
  .banner {
    margin-top: 64px;
  }
  .nav {
    width: 78vw;
    margin: 30px auto 0px;
    /* .content:nth-child(9) {
      display: none;
    } */
    .content {
      width: 100%;
      div {
        font-size: 18px;
        font-weight: 500;
        text-align: center;
      }
      .text {
        font-size: 12px;
        margin-bottom: 5px;
        font-weight: 300;
        text-align: left;
        color: #adadad;
        margin-bottom: 20px;
        /* display: none; */
      }
    }
  }
}
</style>
